import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCredorList() {
  // Use toast
  const toast = useToast()

  const refCredorListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'nome', sortable: true },
    { key: 'cnpj', sortable: true },
    { key: 'codigo', sortable: true },
    { key: 'email', sortable: true },
    { key: 'telefone', sortable: true },
    { key: 'situacao' },
    { key: 'actions', label: "Ações" },
  ]
  const perPage = ref(15)
  const currentPage = ref(1)
  const perPageOptions = [10, 15, 25, 50, 100]
  const searchQuery = ref('')
  const situacaoFilter = ref(null)

  const dataMeta = ref({})

  // const totalRows = computed(() => {
  //   return dataMeta.total
  // })

  const refetchData = () => {
    refCredorListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situacaoFilter], () => {
    refetchData()
  })

  const fetchCredores = (ctx, callback) => {
    store
      .dispatch('app-credor/fetchCredores', {
        busca: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        situacao: situacaoFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data     
        callback(data)
        dataMeta.value = meta
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching credor list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCredorStatusVariant = status => {
    if (status === 'ATIVO') return 'success'
    if (status === 'BLOQUEADO') return 'danger'
    if (status === 'CANCELADO') return 'warning'
    return 'primary'
  }

  return {
    fetchCredores,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    refCredorListTable,

    resolveCredorStatusVariant,
    refetchData,

    // Extra Filters
    situacaoFilter,
  }
}
