<template>
  <div>

    <!-- Adicionar novo -->
    <credor-list-add-new
      :is-add-new-credor-sidebar-active.sync="isAddNewCredorSidebarActive"
      :situacao-options="situacaoOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <credor-list-filters
      :situacao-filter.sync="situacaoFilter"
      :situacao-options="situacaoOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 select-size-sm"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Procurar..."
                debounce="600"
              />
              <b-button
                variant="primary"
                @click="isAddNewCredorSidebarActive = true"
              >
                <span class="text-nowrap">Adicionar Credor</span>
              </b-button>
              <b-button
                variant="outline-success"
                class="ml-1"
                v-b-modal.modal-import
              >
                <span class="text-nowrap">Importar Planilha</span>
              </b-button>

              <!-- modal login-->
              <b-modal
                id="modal-import"
                cancel-variant="outline-danger"
                ok-title="Importar"
                cancel-title="Fechar"
                centered
                title="Importar planilha de credores"
                @ok="submitImport"
              >
                <b-form>
                  <b-form-group>
                    <label for="email">Arquivo:</label>
                    <b-form-file
                      ref="file"
                      v-model="fileImport"
                      placeholder="Escolha um arquivo..."
                      drop-placeholder="Arraste um arquivo aqui..."
                      accept=".xlsx, .xls"
                    />

                    <b-card-text class="my-1">
                      Arquivo selecionado: <strong>{{ fileImport ? fileImport.name : '' }}</strong>
                    </b-card-text>
                  </b-form-group>
                </b-form>
              </b-modal>

            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCredorListTable"
        class="position-relative"
        responsive
        striped
        hover
        :items="fetchCredores"
        :fields="tableColumns"  
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <!-- Column: User -->
        <template #cell(nome)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.nome)"
                variant="light-danger"
                :to="{ name: 'credor-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'credor-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap" 
            >
              {{ data.item.nome }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(situacao)="data">
          <b-dropdown
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            split
            right
            size="sm"
            :text="data.item.situacao"
            :variant="resolveCredorStatusVariant(data.item.situacao)"
          >
            <b-dropdown-item @click="updateSituationCredor(data.item.id,'ATIVO')">ATIVO</b-dropdown-item>
            <b-dropdown-item @click="updateSituationCredor(data.item.id,'BLOQUEADO')">BLOQUEADO</b-dropdown-item>
            <b-dropdown-item @click="updateSituationCredor(data.item.id,'CANCELADO')">CANCELADO</b-dropdown-item>
          </b-dropdown>
          <!-- <b-badge
            pill
            :variant="`light-${resolveCredorStatusVariant(data.item.situacao)}`"
            class="text-capitalize"
          >
            {{ data.item.situacao }}
          </b-badge> -->
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item>
              <div @click="showModalDelete(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Excluir</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <!-- Paginação -->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo de {{ dataMeta.from }} até {{ dataMeta.to }} de {{ dataMeta.total }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormFile, BForm, BFormGroup, BCardText
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import CredorListFilters from './CredorListFilters.vue'
import CredorListAddNew from './CredorListAddNew.vue'
import useCredorList from './useCredorList'
import credorStoreModule from '../credorStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    CredorListFilters,
    CredorListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormFile,
    BForm,
    BFormGroup,
    BCardText,

    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const CREDOR_APP_STORE_MODULE_NAME = 'app-credor'

    // Register module
    if (!store.hasModule(CREDOR_APP_STORE_MODULE_NAME)) store.registerModule(CREDOR_APP_STORE_MODULE_NAME, credorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CREDOR_APP_STORE_MODULE_NAME)) store.unregisterModule(CREDOR_APP_STORE_MODULE_NAME)
    })

    const isAddNewCredorSidebarActive = ref(false)

    const fileImport = ref(null)

    function submitImport(){
      if(this.fileImport == null){
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Importação Credores',
              icon: 'FileIcon',
              variant: 'danger',
              text: `Por favor, selecione um arquivo.`,
            },
          })
      }else{
        const file = this.$refs.file.files[0]
        let formData = new FormData();

        formData.append('credores', file);
        axios.post('importar/credores',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Importação Credores',
              icon: 'FileIcon',
              variant: 'success',
              text: `Tudo certo, sua importação está sendo processada!`,
            },
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Importação Credores',
              icon: 'FileIcon',
              variant: 'danger',
              text: `Algo deu errado com seu arquivo ):`,
            },
          })
        });

        this.fileImport = null
      }
    }

    function updateSituationCredor(id,situation){
      axios.put('credor/'+id+'/situacao', 
        {
          'situacao' : situation,
        }
      ).then((response) => {
        this.$refs.refCredorListTable.refresh();
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Situação do credor',
            icon: 'CheckIcon',
            variant: 'success',
            text: `Situação do credor alterada com sucesso!`,
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Situação do credor',
            icon: 'XIcon',
            variant: 'danger',
            text: `Algo deu errado ):`,
          },
        })
      });
    }

    function showModalDelete(id) {
      this.$bvModal
        .msgBoxConfirm('Você deseja prosseguir com a exclusão?', {
          title: 'Confirme sua ação',
          size: 'sm',
          modalClass: 'modal-danger',
          okVariant: 'danger',
          okTitle: 'Sim, confirmar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value == true){
            axios.delete('credores/'+id)
            .then((response) => {
              this.$refs.refCredorListTable.refresh();
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Exclusão de registro',
                  icon: 'FileIcon',
                  variant: 'success',
                  text: `Credor excluído com sucesso!`,
                },
              })
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Exclusão de registro',
                  icon: 'FileIcon',
                  variant: 'danger',
                  text: `Não foi possível excluir seu credor ):`,
                },
              })
            });
          }
        })
    }

    const situacaoOptions = [
      { label: 'Ativo', value: 'ATIVO' },
      { label: 'Bloqueado', value: 'BLOQUEADO' },
      { label: 'Cancelado', value: 'CANCELADO' }
    ]

    const {
      fetchCredores,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      refCredorListTable,
      refetchData,

      // UI
      resolveCredorStatusVariant,

      // Extra Filters
      situacaoFilter
    } = useCredorList()

    return {

      // Sidebar
      isAddNewCredorSidebarActive,

      fetchCredores,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      refCredorListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveCredorStatusVariant,

      situacaoOptions,

      // Extra Filters
      situacaoFilter,

      fileImport,
      submitImport,
      showModalDelete,
      updateSituationCredor
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
